<template>
    <div class="row mb-5 dashboard">
        <div class="col-12">
            <div class="row gy-3">
                <div class="col-md-12">
                    <h5 class="text-primary">Hi, {{activeUser.first_name}}</h5>
                </div>
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Total Members</h5>
                            <p> {{result.members.total}} total members in your group</p>
                            <a class="text-decoration-underline" target="_blank"
                            :href="absoluteUrl(`/groups/${result.group_id || '...'}/member`)"> Add Member </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri ri-shield-star-line align-bottom"></i> Verified Memeber</h5>
                            <p>{{ result.members.verified > 1 ? `${result.members.verified} members are` : `${result.members.verified} member is`}} verified</p>
                            <router-link class="text-decoration-underline" to="/manage-group"> View group </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
                            <p>Information & setttings</p>
                            <router-link class="text-decoration-underline" to="/account"> Account settings </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 align-self-center">
                    <h5 class="mb-2">Your group Code:</h5>
                    <h4><span class="text-primary">{{result.group_id || '...'}}</span></h4>
                    <p>You can copy and share code for your member's registration.</p>
                    <p class="text-primary">{{absoluteUrl(`/groups/${result.group_id || '...'}/member`)}}</p>
                </div>
                <div class="col-md-6 align-self-center">
                    <div style="border-radius:2rem;" class="card col-md-6 text-center loading-viewport">
                        <is-loading v-if="isLoading" :box="true" />
                        <img v-if="result.id_card" :src="absoluteUrl(result.id_card)" class="card-img-top" alt="Group head Id">
                        <div v-else class="card-body position-relative">
                            <p class="mb-0">Your id Card</p>
                        </div>
                    </div>
                    <div class="d-grid gap-3 d-sm-block"> 
                        <button @click.prevent="regenerateIdCard()" class="btn btn-dark me-sm-3" type="button">Regenerate ID</button>
                        <a :href="absoluteUrl(`/download?file_path=${result.id_card}`)" class="btn btn-primary" target="_blank">Download for printing</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
    name: 'dashboard',
    components:{
        UserEmailVerification,
        IsLoading,
    },
    data(){
        return{
            isLoading: true,
            result:{
                members: {
                    total:0,
                    verified: 0
                },
                id_card: "",
                group_id: ""
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    methods:{
        regenerateIdCard(){
            this.isLoading = true;
            this.$http.get('/account/generate-id')
                .then((response) => {
                    this.isLoading = false;
                    if(response.data.success){
                        this.result.id_card = response.data.data;
                    }
            })
        }
    },
    created(){
        this.$http.get('/analytics')
            .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.result = response.data.data;
                }
        })

    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}

</style>
